/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        var menuList, mobileToggle, mobileToggle2, mobileClose;
        mobileToggle = $('.nav-1');
        mobileToggle2 = $('.nav-2');
        mobileClose = $('.nav-primary .close-icon');
        menuList = $('.nav-primary');

        mobileToggle.on('click', function(e) {
          e.preventDefault();
          menuList.slideToggle();
        });

        mobileToggle2.on('click', function(e) {
          e.preventDefault();
          menuList.slideToggle();
        });

        mobileClose.on('click', function(e){
            e.preventDefault();
            menuList.slideToggle();
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $('.testimonial__slider').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
	        prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button">&#9001;</button>',
	        nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button">&#9002;</button>',
          dots: false,
          fade: true,
          pauseOnFocus: true,
          pauseOnHover: true,
          autoplay: true,
          autoplaySpeed: 5000
        });

        $('.hero__slider').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          pauseOnFocus: true,
          pauseOnHover: true,
          autoplay: true,
          autoplaySpeed: 5000
        });

        $(".hero__down_button").click(function(event) {
        event.preventDefault();
          $('html, body').animate({
              scrollTop: $(".services").offset().top
          }, 1500);
        });

        $(".consult__down_button").click(function(event) {
          event.preventDefault();
          $('html, body').animate({
              scrollTop: $(".testimonial").offset().top
          }, 1500);
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
